'use client';

import { useState, useEffect } from 'react';
import * as hookFormDevTools from '@hookform/devtools';
import { useFormContext } from 'react-hook-form';
// https://github.com/react-hook-form/devtools/issues/208
// Not really helping at all
export const RhfDevTool: (typeof hookFormDevTools)['DevTool'] = () => null;
// process.env.NODE_ENV !== 'development-disable'
//   ? () => null
//   : (props) => {
//       const [isMounted, setIsMounted] = useState(false)
//
//       const form = useFormContext()
//
//       useEffect(() => {
//         const timer = setTimeout(() => setIsMounted(true), 3000)
//         return () => clearTimeout(timer)
//       }, [])
//
//       return isMounted ? <hookFormDevTools.DevTool {...props} /> : null
//     }